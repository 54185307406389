import {
  Box,
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AppointmentsFilter from "./components/AppointmentsFilter";
import AppointmentsGrid from "./components/AppointmentsGrid";
import React, { useState } from "react";
import AddAppointmentModal from "./components/AppointmentsAdd";
import { useAppSelector } from "../../redux/hooks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IssuesGrid from "./components/IssuesGrid";

const AppointmentsPage = () => {
  const [showAddAppointmentModal, setShowAddAppointmentModal] = useState(false);
  const currentUser = useAppSelector(
    (state) => state.currentUserState.currentUser
  );
  const [value, setValue] = React.useState("active");
  const [refetch, setRefetch] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddAppointmentModal(true);
  };

  const onDoneAdding = (reload: boolean) => {
    if (reload) {
      setRefetch(true);
    }
    setShowAddAppointmentModal(false);
  };

  const onDoneRefetching = () => {
    setRefetch(false);
  };

  const today = new Date();
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() - 30))
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() + 1))
  );

  const onUpdateFilter = (filter: any | undefined) => {
    console.log(filter);
    setStartDate(filter.startDate);
    setEndDate(filter.endDate);
  };

  return (
    <React.Fragment>
      <Grid container columns={2}>
        <Grid item sm={1}>
          <Breadcrumbs>
            <Typography color="text.primary">Appointments</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {currentUser?.role === "SUPERADMIN" && (
            <Button variant="text" color="secondary" onClick={onAdd}>
              + Add
            </Button>
          )}
        </Grid>
      </Grid>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="Active" value="active" />
            <Tab label="Not Scheduled" value="not_scheduled" />
            <Tab label="Issues" value="issues" />
            <Tab label="Completed" value="appointments" />
          </TabList>
        </Box>
        <TabPanel value="appointments">
          <Stack spacing={2} sx={{ mt: 2 }}>
            <AppointmentsFilter
              startDate={startDate}
              endDate={endDate}
              changeHandler={onUpdateFilter}
            />
            <AppointmentsGrid
              startDate={startDate}
              endDate={endDate}
              statuses={["Complete", "CompleteAndSend"]}
              refetch={refetch}
              finishRefetch={onDoneRefetching}
            />
          </Stack>
        </TabPanel>
        <TabPanel value="not_scheduled">
          <Stack spacing={2} sx={{ mt: 2 }}>
            <AppointmentsGrid
              startDate={undefined}
              endDate={undefined}
              statuses={["Purchased"]}
              refetch={refetch}
              finishRefetch={onDoneRefetching}
            />
          </Stack>
        </TabPanel>
        <TabPanel value="active">
          <Stack spacing={2} sx={{ mt: 2 }}>
            <AppointmentsGrid
              startDate={undefined}
              endDate={undefined}
              statuses={["Scheduled","NeedsApproval"]}
              refetch={refetch}
              finishRefetch={onDoneRefetching}
            />
          </Stack>
        </TabPanel>
        <TabPanel value="issues">
          <IssuesGrid />
        </TabPanel>
      </TabContext>
      <AddAppointmentModal
        doneAdding={onDoneAdding}
        show={showAddAppointmentModal}
      />
    </React.Fragment>
  );
};

export default AppointmentsPage;
